<template>
<main>
    <div style="background-color:#E23336; height: 160px; width: 100vw;" class="topbg">
      <v-container class="d-flex topbg-container">
      <h1 style="color: #FFF;padding-top: 0;" class="container mt-0" v-if="$store.state.products.length || $store.state.cart_plans.length">Finalizar compra</h1>
      <div v-if="$store.state.roulettePrize?.uid == 'percent' && ($store.state.products.length || $store.state.cart_plans.length)" class="RouletteContainer__Text">
        <h4 class="RouletteContainer__Text__Oferta" style="color: #FFFF; font-size: 24px;  margin-bottom: 0; font-weight: 800;">Descuento válido por lo próximos :</h4>
        <span id="time"  class="RouletteContainer__Text__Count">00:00</span>
      </div>
      </v-container>

    </div>
  <v-container class="checkout-container" style="margin-bottom:3rem; display:flex; justify-content:center; margin: 0 auto; max-width: 1300px;">

<!--     CHECKOUT -->
    <section v-if="!loaderCheckout && ($store.state.products.length)" class="Checkout" style="margin-top: 20px;">

        <v-row class="flex-column-reverse flex-md-row" style="gap: 40px; justify-content: center; margin: 0 auto; max-width: 1500px;"> 
            <v-col id="formulario" cols="12" md="7" style="scroll-margin-top: 120px; background-color: #FFFFFF; box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0,0,0,0.1); border-radius: 0.4375rem; padding: 20px;">
                <div v-if="!logged" style="display:flex; justify-content:space-between;">
<!--                   <h3>{{registerForm ? 'Registro': 'Ingresar'}}</h3> -->
<!--                   <v-btn text @click="registerForm = !registerForm" style="z-index:1;">{{registerForm ? 'ya tengo cuenta': 'registrarme'}}</v-btn> -->
                </div>
                <div  style="display:flex; justify-content:space-between; align-items:center;" :style="$vuetify.breakpoint.mobile ? 'flex-direction: column-reverse;':''">
                  <h3>Datos de envío</h3>
                  <v-btn v-if="logged" text @click="logout" style="z-index:1;">Cambiar de cuenta</v-btn>
                </div>
                  <v-expansion-panels v-if="!logged" style="margin-top: 20px; margin-bottom: 20px;">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Ya tengo una cuenta
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        
                        <v-form ref="form" v-model="valid" lazy-validation >
                          <v-row>
                          
                          <v-col cols="12" md="4">
                            <v-text-field v-model="cart.email" :rules="emailRules" label="Correo Electrónico" required></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field type="password" label="Contraseña" v-model="password"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" class="mt-3">

                                <v-btn :loading="loadingbtn" style="z-index: 1; margin-right:8px;background-color: #E23336; color: #FFFF;"   @click="logIn">Ingresar</v-btn>
                                <span v-if="messageLogin"  style="display: block; margin-bottom: 8px;">* {{messageLogin}}</span>
                          </v-col>
                          </v-row>
                        </v-form> 
                      </v-expansion-panel-content>
                        </v-expansion-panel>
                  </v-expansion-panels>
                
            <v-form class="mt-4" ref="form" v-model="valid" lazy-validation v-if="registerForm">
                <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field dense required :rules="required" v-model="cart.name" label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="logged">
                      <v-text-field dense required :rules="required" v-model="cart.last_name" label="Apellido"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field dense v-model="cart.email" :rules="emailRules" label="Correo Electrónico" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="!logged">
                        <v-text-field dense v-model="rut" :rules="rutRules" @change="(rut) => {formatRut(rut)}" required label="Rut"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field dense maxlength="11" :counter="11" :rules="requiredPhone" required v-model="cart.phone" label="Teléfono"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="!logged">
                      <v-select dense required label="Género" :items="genderOptions" item-text="text" item-value="value"  v-model="sexo"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field dense required :rules="required" v-model="cart.address" label="Dirección"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="logged">
                      <v-text-field dense  v-model="cart.zip_code" label="Código ZIP"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select dense disabled :rules="required" label="País" :items="paises" item-text="name" item-value="id" v-model="pais"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select dense :rules="required" label="Región" :items="regiones" item-text="region" item-value="id" v-model="region"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select dense :rules="required" label="Comuna" :disabled="!region" :items="comunas" item-text="comuna" item-value="id" v-model="cart.comuna_id"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="logged && ($store.state.products.length || !$store.state.cart_plans[0].months)">
                      <v-autocomplete
                        dense
                        :disabled="loadingbtn"
                        style="z-index: 1;"
                        :rules="required"
                        v-model="city"
                        :items="cities"
                        :loading="isLoading"
                        :search-input.sync="search"
                        hide-no-data
                        hide-selected
                        item-text="nombre_ciudad"
                        item-value="codigo_ciudad"
                        label="Ciudad"
                        placeholder="Buscar Ciudad"
                        prepend-icon="mdi-magnify"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" style="z-index:1;" v-if="!logged">
                      <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    style="z-index: 1 !important;"
                    v-model="birthday"
                    :rules="required"
                    label="Fecha Nacimiento"
                    readonly
                    v-bind="attrs"
                    prepend-inner-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  locale="es-ES"
                  v-model="birthday"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="(date) => $refs.menu.save(date)"
                ></v-date-picker>
              </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field dense v-if="!logged" :rules="required" required type="password" label="Contraseña" v-model="password"></v-text-field>
                    </v-col>
                </v-row>
                <span v-if="message" style="display: block; margin-bottom: 8px;">* {{message}}</span>

                <v-btn text :loading="loadingbtn" v-if="!logged" style="z-index: 1; margin-right:8px; padding-right: 0;"  color="dark" @click="signUpByCode">Registrar cuenta</v-btn>
              </v-form>
            </v-col>    



<!--         RESUMEN DE COMPRA -->
            <v-col cols="12" md="4" style="padding:0;">
            <div class="OrderSumary">
              <h4 class="OrderSumary__Title">Resumen de compra</h4>

<!--           PRODUCTOS -->
              <div class="OrderSumary__Header" v-for="product in this.$store.state.products" :key="product.product_id">
                <img class="OrderSumary__Header__Img" :src="product.image[0].image" alt="sosclick_img">
                <div class="OrderSumary__Header__Data">
                  <h5 class="OrderSumary__Header__Data__Name">{{productName(product.product_id)}}</h5>
                  <p class="OrderSumary__Header__Data__Quantity"><span style="color: blue; font-weight: 500; font-size: 16px;">$ {{ product.price | formatNumber}}</span> x {{ product.qty }}</p>

               <div v-if="product.product_id !== 10" class="quantity-container">
              <button :disabled="loadingbtn || loadingEnvio" class="quantity-btn" @click="removeItem(product.product_id)">-</button>
              <div class="quantity-visor">{{ product.qty }}</div>
              <button :disabled="loadingbtn || loadingEnvio" class="quantity-btn" @click="addItem(product.product_id)">
                +
              </button>
            </div>
                </div>
                <v-btn :disabled="loadingbtn || loadingEnvio" @click="filterProduct(product.product_id)" icon style="margin-left: auto; z-index: 1;"><v-icon>mdi-delete</v-icon></v-btn>
              </div>

              <!-- PLANES -->
<!--                <div class="OrderSumary__Header" v-for="plan in this.$store.state.cart_plans" :key="plan.plan_id"> -->
<!--                 <img class="OrderSumary__Header__Img" :src="require('../../assets/phone.png')" alt="sosclick_img"> -->
<!--                 <img class="OrderSumary__Header__Img" :src="imgUrl(plan.product_id)" alt="sosclick_img">
                <div class="OrderSumary__Header__Data">
                  <h5 class="OrderSumary__Header__Data__Name">{{planName(plan.product_id)}}</h5>
                  <p class="OrderSumary__Header__Data__Quantity"><span style="color: blue; font-weight: 500; font-size: 16px;">$ {{ plan.amount | formatNumber}}</span></p>

                <div v-if="plan.months">
                  <v-select :disabled="loadingbtn || loadingEnvio" class="mt-2" style="max-width: 150px;" outlined dense required label="Suscripción" :items="monthsOptions" item-text="text" item-value="value"  v-model="plan_months"></v-select>
                </div>
                </div>
                <v-btn :disabled="loadingbtn || loadingEnvio" @click="filterPlan(plan.plan_id)" icon style="margin-left: auto; z-index: 1;"><v-icon>mdi-delete</v-icon></v-btn>
              </div> -->


                <!-- Montos -->
              <div class="OrderSumary__Price">
                <div class="OrderSumary__Price__Item">
                  <p style="font-weight: 500;">Sub-Total:</p>
                  <p>$ {{ cart_data.total | formatNumber}}</p>
                </div>
                <div style="color: green;" class="OrderSumary__Price__Item" v-if="discount">
                  <p style="font-weight: 500;">{{coupon_description}}:</p>
                  <p style="min-width: 72px;">- $ {{ discount | formatNumber }}</p>
                </div>
                <div v-if="discount" style="background-color: #EBEBEB; border-radius: 5px; width: fit-content;padding: 4px 10px;margin-bottom: 12px;"><span><v-icon>mdi-tag</v-icon> {{applied_code}}</span> <span @click="removeDiscount" style="color: #838383; font-weight:500;margin-left: 4px;cursor:pointer; z-index: 50;position:relative;">X</span></div>
                <div class="OrderSumary__Price__Item" v-if="logged && ($store.state.products.length || !$store.state.cart_plans[0].months)">
                  <p style="font-weight: 500;margin-bottom:0;">Envío<v-icon style="margin-right: 4px; margin-left: 4px;">mdi-truck</v-icon>:</p>
                  <p v-if="costo_envio && city" style="margin-bottom:0;">{{costo_envio.costoTotal | formatNumber}}</p>
                  <p v-else style="margin-bottom:0;">Seleccione Ciudad</p>
                </div>
                <p class="mt-1" style="color: green;" v-if="total_qty < 4 && !envio_gratis">Añade {{ 4 - total_qty }} productos para obtener un 10% de descuento</p>
                <p class="mt-1" style="color: green;" v-else >10% de descuento aplicado</p>
                  <v-select
                    :disabled="loadingbtn || loadingEnvio"
                    v-if="city && ($store.state.products.length || !$store.state.cart_plans[0].months)"
                    v-model="costo_envio"
                    :items="listado_tarifas"
                    item-text="diasEntrega"
                    item-value="costoTotal"
                    label="Opciones de Envío a Domicilio"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>

                <div class="OrderSumary__Price__Item OrderSumary__Price__Item--total">
                  <p style="font-weight: 600;">Total:</p>
                  <div v-if="!loaderCheckout">
                    <p v-if="costo_envio && city" style="font-weight: 600;">$ {{(envio_gratis ? cart_data.to_pay :  cart_data.to_pay + costo_envio.costoTotal) | formatNumber}}</p>
                    <p v-else style="font-weight: 600;">$ {{cart_data.to_pay | formatNumber}}</p>
                  </div>
                  
                  <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
                </div>
              </div>

              <v-btn  block :loading="loadingbtn || loadingEnvio" @click="buy" style="background-color: #E23336; color: #FFF;z-index: 1;"><v-icon left>
        mdi-check-circle-outline
      </v-icon>Comprar</v-btn>
          <img src="../../assets/imgs/webpay.png" style="width: 100px; display: flex; justify-content: center; margin:0 auto; margin-top: 12px;" alt="webpay">



              <v-text-field style="margin-bottom: 0; padding-bottom: 0;" class="mt-4" outlined v-model="code" label="Código Descuento"></v-text-field>
              <v-btn :disabled="!code" @click="getCartData" block outlined class="mt-0" style="background-color: #E23336; color: #FFF;z-index: 1;">Aplicar Código</v-btn>
            <small style="color:red;">{{error_code}}</small>
            </div>
            </v-col>
        </v-row>
    </section>

    <section v-if="loaderCheckout" style="margin-top: 20px;">
      Cargando...
    </section>

    <section v-if="!$store.state.products.length && !$store.state.cart_plans.length" style="display:flex; flex-direction: column; align-items:center; gap: 12px;">
      <h2 style="color: #717171;">El Carro está vacío</h2>
      <v-icon style="font-size: 50px;">mdi-cart-off</v-icon>
      <v-btn  class="mt-2" color="#E23336" style="color: #FFFF;z-index: 123;" @click="$router.push('/catalogo')">Ir al Catálogo</v-btn>
    </section>

  </v-container>
    <section class="CheckoutFooter">
      <div class="CheckoutFooter__Item">
        <v-icon class="CheckoutFooter__Item__Icon" color="white darken-2">mdi-rocket-launch</v-icon>
        
        <div class="CheckoutFooter__Item__Text">
          <h5 class="CheckoutFooter__Item__Text__Title">Envío rápido y seguro</h5>
<!--           <p class="CheckoutFooter__Item__Text__Subtitle">Todas las compras cuentan con envío gratuito dentro de la RM</p> -->
        </div>  
      </div>
      <div class="CheckoutFooter__Item">
        <v-icon class="CheckoutFooter__Item__Icon" color="white darken-2">mdi-cash</v-icon>
        
        <div class="CheckoutFooter__Item__Text">
          <h5 class="CheckoutFooter__Item__Text__Title">Garantía y devolución</h5>
          <p class="CheckoutFooter__Item__Text__Subtitle">Nuestros productos están garantizados</p>
        </div>  
      </div>
      <div class="CheckoutFooter__Item">
        <v-icon class="CheckoutFooter__Item__Icon" color="white darken-2">mdi-headset</v-icon>
        
        <div class="CheckoutFooter__Item__Text">
          <h5 class="CheckoutFooter__Item__Text__Title">Soporte comercial y técnico</h5>
          <p class="CheckoutFooter__Item__Text__Subtitle">Comúnicate por cualquiera de nuestros canales para recibir ayuda</p>
        </div>  
      </div>
      <div class="CheckoutFooter__Item">
        <v-icon class="CheckoutFooter__Item__Icon" color="white darken-2">mdi-credit-card</v-icon>
        
        <div class="CheckoutFooter__Item__Text">
          <h5 class="CheckoutFooter__Item__Text__Title">Pago Seguro</h5>
          <p class="CheckoutFooter__Item__Text__Subtitle">Nuestra web cuenta con pago seguro a través de WebPay</p>
        </div>  
      </div>
    </section>
<!--     <v-dialog :width="width < 568 ? 'auto' : 'unset'" :content-class="width < 568 ? 'vdialognew' : ''"  v-model="dialogWheel" v-if="dialogWheel" > 
      <Wheel :product_id="$route.query.product_id" :closeRoulette="closeRoulette"/>
    </v-dialog> -->
    </main>
</template>

<script>
import { validate, format } from 'rut.js'
import { createPurchaseOrder, getPaisesActivos, getRegionesActivas, getComunasActivas, signUpByCode,  login, getUser, getCartData, listadoCiudadesDestino/* , getToPayData */ } from '../../Helpers/api/general'
import _ from 'lodash/lodash';
import moment from "moment";
//import Wheel from '../Modals/Wheel.vue'


export default {
/*   components: { Wheel }, */
    data() {
        return {
    cart_data: {},
    loader: false,
    logged: false,
    registerForm: false,
    valid: true,
    genderOptions: [{text: 'Masculino', value:1}, {text: 'Femenino', value:2}],
    requiredPhone: [
        v => !!v || 'Este campo es obligatorio',
            v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números'
        ],
        rutRules: [
            v => !!v || 'Este campo es obligatorio',
            v => (!!v || 'Este campo es obligatorio') && (validate(v) || 'Rut no válido')
          ],
        emailRules: [
            v => !!v || 'Email es obligatorio',
			v => /^[^\s]+@[^\s]+\.[^\s]+$/.test(v) || 'Email debe ser válido',
		],
            required: [v => !!v || 'Este campo es obligatorio',],
            loadingbtn: false,
    step: 1,
    menu: false,
    price: 123,
    paises: [],
    pais: 81,
    regiones: [],
    region: null,
    comunas: [],
    cart: {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        zip_code: '',
        comuna_id: ''
      },
      qty: 1,
      password: '',
      rut: '',
      birthday: new Date().toISOString().substr(0, 10),
      sexo: '',
      message: '',
      messageLogin: '',
      code: '',
      applied_code: '',
      discount: null,
      product: {},
      error_code: null,
      loaderCheckout: true,
      //search city
      isLoading: false,
      city: null,
      search: null,
      cities: [],
      listado_tarifas: [],
      tarifa_envio: null,
      costo_envio: null,
      loadingEnvio: false,
      no_products: false,

      dialogWheel: false,


    monthsOptions: [
      {text: '1 Mes', value:1},
      {text: '3 Meses', value:3},
      {text: '6 Meses', value:6},
      {text: '12 Meses', value:12},
    ],
    plan_months: 12,
    envio_gratis: false
    };
  },
  computed: {
    width() {
      return window.screen.width
    },
    total_qty() {
      return this.$store.state.products.reduce((acc, producto) => acc + producto.qty, 0);
    }
  },
/*   computed: {
    fields () {
      if (!this.model) return []
      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      })
    },
    cities () {
      return this.entries.map(entry => {
        const Description = entry.Description.length > this.descriptionLimit
          ? entry.Description.slice(0, this.descriptionLimit) + '...'
          : entry.Description
        return Object.assign({}, entry, { Description })
      })
    },
  }, */
  methods: {
    productName(id) {
      const names = {
        10: 'Plan Anual + SOSCAM Gratis',
        9: 'SOSCAM EXT-A',
        12: 'Router 4g Wifi',
        8: 'SOSCAM'
      }
      return names[id]
    },
    planName(id) {
      const names = {
        9: 'Plan Anual + SOSCAM Ext-A, Gratis',
        8: 'Plan Anual + SOSCAM, Gratis',
        11: 'Router 4g Wifi + Cámara Seguridad Int',
        13: 'Router 4g Wifi + Cámara Seguridad Ext'
      }
      return names[id]
    },
    imgUrl(id) {
      const paths = {
        10: require('../../assets/plan.png'),
        9: require('../../assets/soscamexta.png'),
        8: require('../../assets/Camara.png'),
        11: require('../../assets/combo-int.png'),
        12: require('../../assets/router.png'),
        13: require('../../assets/combo-ext.png'),
      }
      return paths[id]
    },
    addItem(product_id) {
      this.loadingbtn = true
      const cartCopy = [...this.$store.state.products];
      const productIndex = cartCopy.findIndex(item => item.product_id === product_id);
      if (productIndex !== -1) {
        cartCopy[productIndex].qty++;
      this.$store.commit('setCart', cartCopy);
      this.qty = this.qty + 1
      }
    },
    removeItem(product_id) {
      const cartCopy = [...this.$store.state.products];
      const productIndex = cartCopy.findIndex(item => item.product_id === product_id);
      
      if (productIndex !== -1 &&  cartCopy[productIndex].qty !== 1) {
        cartCopy[productIndex].qty--
        this.$store.commit('setCart', cartCopy);
        this.qty = this.qty - 1
      }
    },
    filterProduct(product_id) {
      const cartCopy = [...this.$store.state.products];
      const filteredCart = cartCopy.filter(product => product.product_id !== product_id);
      this.$store.commit('setCart', filteredCart);
      this.qty = this.qty - 1
    },
    filterPlan(plan_id) {
      const cartCopy = [...this.$store.state.cart_plans];
      const filteredCart = cartCopy.filter(plan => plan.plan_id !== plan_id);
      console.log(filteredCart);
      
      this.$store.commit('setCartPlans', filteredCart);
      this.qty = this.qty - 1
    },
      formatRut(rut) {
        this.rut = format(rut)
      },
    next() {
        this.loader = true;
        setTimeout(() => {
            this.loader = false;
            this.step = 2;
            localStorage.setItem('qty', JSON.stringify(this.qty));
        }, 1000);
    },
    signUpByCode() {
    const data = {
        name: this.cart.name,
        email: this.cart.email,
        direccion: this.cart.address,
        telefono: this.cart.phone,
        rut: this.rut,
        sexo: this.sexo,
        birthday: this.birthday,
        password: this.password,
        pais: this.pais,
        region: this.region,
        comuna: this.cart.comuna_id
        }
      if(this.$refs.form.validate()){
        this.loadingbtn = true
        signUpByCode(data).then(() => {
          this.loadingbtn = false;
            this.message = '';
            this.logIn()
        }).catch(er => {
          this.loadingbtn = false
          this.message = er[0].includes('Ese email ya está en uso, por favor verifica.') ? er[0] : null
        })
      }
    },
    logIn() {
      this.loadingbtn = true
      login({email: this.cart.email, password: this.password}).then(() => {
        this.loadingbtn = false
        this.logged = true
        this.registerForm = true
        getUser().then(res => {
          const { user } = res
          this.cart.name = user.name
          this.cart.email = user.email
          this.cart.address = user.info.direccion
          this.cart.phone = user.info.telefono
/*           this.pais = user.info.pais */
          this.region = user.info.region
          this.cart.comuna_id = user.info.comuna

          this.$store.commit('setUserData', user)
        })
        
        this.messageLogin = ''
        location.reload();
      }).catch(err => {
        console.log(err);
        
        if (err?.response?.status == 401) {
          this.messageLogin = 'Contraseña Incorrecta'
        }
        this.loadingbtn = false
      })
    },
    logout() {
      localStorage.removeItem("userToken");
      this.logged = false;
      this.region = null
      this.cart = {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        zip_code: '',
        comuna_id: null
      }
      this.qty = 1,
      this.password = ''
      this.rut = ''
      this.birthday = new Date().toISOString().substr(0, 10)
      this.sexo = ''
    },  
    buy() {
        if(this.$refs.form.validate() && this.logged){
          this.loadingbtn = true
            const data = this.cart

            const plans_qty = this.$store.state.cart_plans.length && !this.$store.state.cart_plans[0].months ? this.$store.state.cart_plans.length : 0
            data.qty = this.cart_data.products.reduce((total, product) => total + product.qty, 0) + plans_qty
            data.coupon_code = this.code
            data.zip_code = data.zip_code ? data.zip_code : null 
            data.costo_envio = this.$store.state.products || this.$store.cart_plans[0].months ? this.costo_envio?.costoTotal : null
            data.codigo_ciudad = this.city?.codigo_ciudad && this.city?.codigo_ciudad
            data.products = this.cart_data.products.map(product => {
              return {id: product.product_id, qty: product.qty}
            })
            data.plans = this.$store.state.cart_plans.length ? this.$store.state.cart_plans : null
            data.plans = this.$store.state.cart_plans.map(plan => {
              return {...plan, id: plan.plan_id, qty: plan.qty}
            })

            if (!this.code || this.error_code) {
              delete data.coupon_code
            }
            
            createPurchaseOrder(data).then(res => {
              window.location.href = `${res.flowData.url}?token=${res.flowData.token}`

          }).catch(function (error) {
            this.loadingbtn = false
            console.log(error);
            });
        }
        else {
          const formulario = document.getElementById("formulario");
          window.scrollTo({
            top: formulario.offsetTop - 130, // Obtiene la posición del div en el documento
            behavior: "smooth" // Opcional: agrega un efecto de desplazamiento suave
          });
        }
    },
    getCartData() {
          this.loadingbtn = true
          const products =  this.$store.state.products.map(product => ({ ...product, id: product.product_id }));
          const plans = this.$store.state.cart_plans.map(plan => ({ ...plan, id: plan.plan_id, months: plan.months ? this.plan_months : null, product_id: plan.product_id }));
          const products_qty = this.$store.state.products.reduce((acc, producto) => acc + producto.qty, 0);

            const data = {plans, products, products_qty, coupon_code: this.code  }
            if (this.city) {
              data.codigo_ciudad = this.city.codigo_ciudad
            }

            this.applied_code = this.code


      if (!this.code) {
        delete data.coupon_code
        this.envio_gratis = false
      }
            
            const self = this
            getCartData(data).then(res => {
              if (res?.coupon == 'invalid_purchase_code') {
                this.envio_gratis = false
                self.error_code = 'El código ingresado no existe'

                self.discount = false
                self.cart_data = res.cart_data
                self.loadingbtn = false
                self.$store.commit('setRoulettePrize', null)
                localStorage.removeItem('prize')
                return
              }
              if (res?.coupon == 'expired_purchase_code') {
                this.envio_gratis = false
                self.error_code = 'El código ha expirado'

                self.discount = false
                self.cart_data = res.cart_data
                self.loadingbtn = false
                self.loadingEnvio = false
                self.$store.commit('setRoulettePrize', null)
                localStorage.removeItem('prize')

               if (self.$route.query.product_id) {
                self.dialogWheel = true
                self.$store.commit('setRoulettePrize', null)
                localStorage.removeItem('prize')
          }
                return
              } else {
                
                self.error_code = null
              self.loadingbtn = false
              self.loadingEnvio = false
              self.discount = res.cart_data.discount
              self.cart_data = res.cart_data
              self.$store.commit('setCart', res.cart_data.products)
              if (res.cart_data.plans?.plan_id) {
                self.$store.commit('setCartPlans', [{...res.cart_data.plans, product_id: res.cart_data.plans?.product?.id}])
              }
              if (res.coupon && res.coupon !==  'invalid_purchase_code' && res.coupon !==  'expired_purchase_code') {
                self.coupon_description = res.coupon.description
              
                
                if(res.coupon.type !== 3) {
                  this.envio_gratis = false
                  const expirationTime = moment(res.coupon.valid_to).diff(moment(), 'seconds')
                  const display = document.querySelector('#time');
                  this.startTimer(expirationTime, display);
                } else {
                  this.discount = this.costo_envio.costoTotal
                  this.envio_gratis = true
                }
              }
                }

              self.loaderCheckout = false;
          }).catch(function (error) {
            self.loadingbtn = false
            console.log(error);
            });
    },
    removeDiscount() {
      this.code = '';
      this.coupon_description = '';
      this.getCartData();
      this.discount = null;
    },
    listadoCiudadesDestino() {
      const data = {search: this.search }
/*       if (this.city) {
        data.codigo_ciudad = this.city.codigo_ciudad
        data.cart_qty = this.cart_data.products.reduce((total, product) => total + product.qty, 0);
      } */
      listadoCiudadesDestino(data).then(res => {
        this.cities = res.data.map(city => {
          return {codigo_ciudad: city.codigo_ciudad, nombre_ciudad: city.nombre_ciudad}
        })
      
        this.isLoading = false
      })
    },
    startTimer(duration, display) {
    var timer = duration, minutes, seconds;
      const self  = this
    const timeInterval = setInterval(function () {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;
        
        if (--timer <= 0) {
          clearInterval(timeInterval);
          self.$store.commit('setRoulettePrize', null)
          localStorage.removeItem('prize')
          timer = 0
          if (self.$route.query.product_id == 8 || self.$route.query.product_id == 8) {
            self.dialogWheel = true
          }
            /* timer = duration; */
        }
    }, 1000);
},
  closeRoulette() {
    this.dialogWheel = false;
  },
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setTimeout(() => {
      
      if (this.$route.query.product_id == 8) {
        this.$store.commit('setCart', [{product_id: 8, qty: 1, }])
        setTimeout(() => {
          if (!this.$store.state.roulettePrize) {
            this.dialogWheel = true
          }
        }, 500);
    } else if (this.$route.query.product_id == 9) {
      this.$store.commit('setCart', [{product_id: 9, qty: 1, }])
        setTimeout(() => {
          if (!this.$store.state.roulettePrize) {
            this.dialogWheel = true
          }
        }, 500);
    }
        }, 500);

/*     this.code = null */
    
    setTimeout(() => {
      if (this.$store.state.products.length || this.$store.state.cart_plans.length) {
        this.code = this.$store.state.discountCode;
        this.getCartData()
      } else {
        this.no_products = true
        this.loaderCheckout = false
      }
    }, 1000);

    getPaisesActivos().then(res => {
        this.paises = res.paises
        getRegionesActivas({pais_id: 81}).then(res => {
            this.regiones = res.regiones
        })
    })

    getComunasActivas({region_id: this.region}).then(res => {
      this.comunas = res.comunas
    })
    setTimeout(() => {
      if (this.region == 7) {
        this.search = 'santiago'
      }
    }, 500);



    const userToken = localStorage.getItem('userToken')
    if (userToken) {
      this.logged = true
      this.registerForm = true
      getUser().then(res => {
        const { user } = res
        this.cart.name = user.name
        this.cart.email = user.email
        this.cart.address = user.info.direccion
        this.cart.phone = user.info.telefono
        this.pais = user.info.pais ? user.info.pais : 81
        this.region = user.info.region
        this.cart.comuna_id = user.info.comuna
        this.$store.commit('setUserData', user)
      })
    } else {
      this.logged = false
      this.registerForm = true
    }


/*     const fiveMinutes = 60 * 30
    const display = document.querySelector('#time');
    this.startTimer(fiveMinutes, display); */
  },
  watch: {
    pais: function () {
        getRegionesActivas({pais_id: this.pais}).then(res => {
            this.regiones = res.regiones
        })
    },
    region: function () {
        getComunasActivas({region_id: this.region}).then(res => {
            this.comunas = res.comunas
            setTimeout(() => {
              if (this.region == 7) {
                this.search = 'santiago'
              }
            }, 500);
            setTimeout(() => {
              if (this.region == 7) {
                this.city = this.cities[0]
              }
          }, 3000);
        })
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    plan_months() {
      this.getCartData()
    },
    qty() {
      if (this.city) {
        this.loadingEnvio = true
/*         let cart_qty = this.$store.state.products.reduce((total, product) => total + product.qty, 0);
        if (this.$store.state?.cart_plans[0]?.plan_id && !this.$store.state?.cart_plans[0]?.months) {
          cart_qty = cart_qty + 1
        } */
        let products_qty = JSON.stringify(this.$store.state.products.map(product => {
          return {id: product.product_id, qty: product.qty}
        }))
        
        const data = {codigo_ciudad: this.city.codigo_ciudad, products_qty, search: this.search} 

        
        listadoCiudadesDestino(data).then(res => {
          this.listado_tarifas = res.tarifa_envio.listado_tarifas.filter(tarifa => tarifa.tipoEntrega.codigoTipoEntrega === 2 && tarifa.tipoServicio.codigoTipoServicio === 0).map(tarifa => {
            return {diasEntrega: `${tarifa.costoTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} - Entrega en ${tarifa.diasEntrega} ${tarifa.diasEntrega === 1 ? 'día' : 'días'}`, costoTotal: tarifa.costoTotal }
          });
          this.costo_envio = this.listado_tarifas[0];
          this.getCartData()
        }).catch(e => {
          console.log(e);
        })
      } else {
        this.getCartData()
      }
    },
    
    search: _.debounce(function () {
/*         if (this.search.length > 2 && !this.cities.some(city => city.codigo_ciudad == this.city.codigo_ciudad)) { */
        if (this.search.length > 2) {
        this.isLoading = true

        this.listadoCiudadesDestino()
        }
      }, 1000),
    city(val) {
      if (val) {
        this.loadingbtn = true
        let products_qty = JSON.stringify(this.$store.state.products.map(product => {
          return {id: product.product_id, qty: product.qty}
        }))
        
        const data = {codigo_ciudad: this.city.codigo_ciudad, products_qty, search: this.search} 
        listadoCiudadesDestino(data).then(res => {
          this.listado_tarifas = res.tarifa_envio.listado_tarifas.filter(tarifa => tarifa.tipoEntrega.codigoTipoEntrega === 2 && tarifa.tipoServicio.codigoTipoServicio === 0).map(tarifa => {
            return {diasEntrega: `${tarifa.costoTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} - Entrega en ${tarifa.diasEntrega} ${tarifa.diasEntrega === 1 ? 'día' : 'días'}`, costoTotal: tarifa.costoTotal }
          });
          this.costo_envio = this.listado_tarifas[0];
          this.getCartData()
        })
      }
    },

  },
  filters: {
    formatNumber: function(value) {
      return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.quantity-visor {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quantity-btn {
  z-index: 1;
  background-color: #e1e8ee;
  color: #000;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buy-btn {
  background-color: #E23336;
  color: #fff;
  height: 100%;
  min-height: 60px;
  border-radius: 12px;
  width: 300px;
  text-transform: uppercase;
position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quantity-container {
  border: solid 2px #e1e8ee;
  display: inline-flex;
}
@media (max-width: 568px) {
  .buy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .quantity-container {
    margin: 0 auto;
    display: flex;
    width: fit-content;
  }
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #fff;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

//Footer Checkout

.CheckoutFooter {
  color: #e1e8ee;
  padding-top: 20px;
  width: 100vw;
  background-color: #E23336;
  border-bottom: solid 1px #d3cbcbce;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__Item {
    text-align: center;
    &__Icon {
      font-size: 40px;
    }
    &__Text {
      &__Title {

      }
      &__Subtitle {
      }
    }
  }
}

@media (min-width: 768px) {
  .CheckoutFooter {
    min-height: 200px;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    padding-left: 20px;
    padding-right: 20px;
  &__Item {
    width: 250px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: center;
    gap: 8px;
    &__Icon {
      font-size: 50px;
    }
    &__Text {
      &__Title {
        font-size: 16px;
      }
      &__Subtitle {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
  }


  //Checkout Resumen

  .OrderSumary {
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0,0,0,0.1);
    border-radius: 0.4375rem;
    padding: 20px;
    background-color: #FFFFFF;
    max-width: 100%;
    &__Title {
      font-weight: 400;
      text-align: center;
    }
    &__Header{
      margin-top: 12px;
      display: flex;
      align-items: center;
      gap: 20px;
      border-bottom: solid 1px #E3E9EF;
      padding-bottom: 8px;
      margin-bottom: 12px;
      &__Data {
        &__Name {
          font-size: 14px;
          font-weight: 600;
        }
        &__Quantity {
          margin-top: 4px;
          margin-bottom: 4px;
        }
      }
      &__Img {
        width: 60px;
      }
    }
    &__Price {
      &__Item {
        display: flex;
        justify-content: space-between;
      &--total {
        font-size: 20px;
      }
      }
    }
  }

@media (min-width: 980px) {
  .OrderSumary {
    max-width: 400px;
    margin: 0 auto;
    margin-right: 0;
  }
}



@media (min-width: 980px) {
  .Checkout {
    position: absolute;
    top: 150px;
    width: 1200px;
  }
}
@media (max-width: 980px) {
  .topbg-container {
    flex-direction: column;
    & h1 {
      margin-bottom: 0;
    }
  }
}

.checkout-container {
  min-height: 100vh;
}

@media (min-width: 1700px) {
  .checkout-container {
  min-height: 600px;
  }
}

//mobile
.RouletteContainer {
    &__Text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 8px;
        &__Oferta {
            text-align: center;
            
        }
        &__Count {
            margin-right: 0;
            background-color: #000;
            color: #FAEAA8;
            padding: 8px 16px;
            border-radius: 14px;
            font-weight: 800;
        }
    }
}

//desktop
@media (max-width: 768px) {
    .RouletteContainer {
    &__Text {
        flex-direction: column;
        gap: 8px;
        &__Oferta {


        }
        &__Count {
            margin-bottom: 8px;
            margin-right: 0;
        }
    }
}
}
</style>
