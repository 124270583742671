<template>
    <div>
        <v-container>
            <v-card>
                <h3 class="text-center pa-2">Ventas</h3>
        <v-data-table
                  :headers="headers"
                  :items="sells"
                  :items-per-page="pagination.rowsPerPage"
                  hide-default-footer
                  class="elevation-1"
                  style="width: 100%"
                >
                  <template v-slot:[`item.order_number`]="{ item }">
                    {{ item?.order_number }}
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    {{ item?.user?.name }}
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ item?.created_at }}
                  </template>

                  <template v-slot:[`item.detail`]="{ item }">
                    <v-btn
                        style="z-index: 123;"
                      color="#3364d0"
                      @click="openDetail(item)"
                      class="ml-3"
                      icon
                      ><v-icon>mdi-information-outline</v-icon></v-btn>
                  </template>
                </v-data-table>
                <div style="width: 500px; margin: 0 auto;z-index: 123;">
                <v-pagination
                class="my-pagination"
                  style="z-index: 123 !important;margin: 8px auto; max-width: 500px;"
                  v-model="pagination.page"
                  :length="Math.ceil(total / pagination.rowsPerPage)"
                  ></v-pagination>
                </div>
            </v-card>
            </v-container>

            <v-dialog v-model="dialogDetail" width="800">
      <v-card style="padding: 20px;">
        <v-card-title class="pl-0 mb-2">Detalle pedido #{{ detail?.order_number }}</v-card-title>

        <h5>Información del Usuario</h5>
        <p>Nombre: {{ detail?.user?.name }}</p>
        <p>Email: {{ detail?.user?.email }}</p>
        <p>Teléfono: {{ detail?.user?.info?.telefono }}</p>

        <h5>Fecha de Compra</h5>
        <p>{{ detail?.created_at }}</p>

        <h5>Envío</h5>
        <p>Dirección: {{ detail?.shipping?.address }}</p>

        <h5>Productos</h5>

        <v-data-table
                  :headers="headers_products"
                  :items="detail?.products"
                  :items-per-page="pagination.rowsPerPage"
                  hide-default-footer
                  class="elevation-1"
                  style="width: 100%"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    {{ item?.product_info?.name }}
                  </template>
                  <template v-slot:[`item.qty`]="{ item }">
                    {{ item?.qty }}
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    {{ item?.product_info?.price | formatNumber }}
                  </template>
                  <template v-slot:[`item.price_to_pay`]="{ item }">
                    {{ item?.price_to_pay | formatNumber }}
                  </template>
        </v-data-table>
        <h4 class="mt-2 mr-3 text-right">Total: {{ detail?.amount | formatNumber }}</h4>

            <v-card-actions>
              <v-spacer></v-spacer>
              <div class="d-flex align-center">
                <v-btn color="primary" text @click="dialogDetail = false" style="z-index: 123;">
                  Cerrar
                </v-btn>
              </div>
            </v-card-actions>


      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { getSellsStore } from '../../Helpers/api/general'
import _ from 'lodash/lodash'

export default {
    data () {
        return {
            sells: [],
            headers: [
                { text: "#", value: "order_number" },
                { text: "Nombre", value: "name", filterable: false, },
                { text: "Fecha", value: "created_at", filterable: false, },
                { text: "Detalle", value: "detail", filterable: false, },
            ],
            headers_products: [
                { text: "Nombre", value: "name", filterable: false, },
                { text: "Cantidad", value: "qty", filterable: false, },
                { text: "Precio Unitario", value: "price", filterable: false, },
                { text: "Subtotal", value: "price_to_pay", filterable: false, },
            ],
            pagination: {
            rowsPerPage: 10,
            page: 1,
        },
        total: null,
        dialogDetail: false,
        detail: null
        }
    },
    methods: {
        getSellsStore() {
            const data = { limit: this.pagination.rowsPerPage, page: this.pagination.page};
            getSellsStore(data).then(res => {
                this.sells = res.data
                this.total = res.total;
            })
        },
        openDetail(item) {
            this.dialogDetail = true;
            this.detail = item;
        },
    },
    watch: {
        "pagination.page": _.debounce(function() {
            this.getSellsStore();
        }, 500),
},
filters: {
    formatNumber: function(value) {
      return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '';
    }
  },
    mounted () {
        this.getSellsStore();
    },
}
</script>

<style lang="scss" >
.v-pagination__item {
    z-index: 123;
}

/* Para cuando está activo */
.v-pagination__item.v-pagination__item--active {
    z-index: 123;
}
</style>